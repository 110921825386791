import React, { useEffect, useState, useContext, Suspense, lazy, Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Loader from "react-loaders";

import { LocationProvider } from './context/LocationContext';
import { AccountProvider } from './context/AccountContext';
import { ExpoBoxProvider } from './context/ExpoBoxContext';
import { OrdersProvider } from './context/OrdersContext';
import { AudioProvider } from './context/AudioContext';

import supabase from './lib/supabase';

import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/general.css';

import { ToastContainer } from "react-toastify";

import AppHeader from "./Layout/AppHeader/";
import AppFooter from "./Layout/AppFooter/";

// Public pages
const Login = lazy(() => import('./public/login'));
const ForgotPassword = lazy(() => import('./public/forgot_password'));

// Account pages
const KDS = lazy(() => import('./pages/kds/index'));
const RecipeBox = lazy(() => import('./pages/recipebox'));
const Waste = lazy(() => import('./pages/waste'));
const Prep = lazy(() => import('./pages/prep'));
const PickupScreen = lazy(() => import('./pages/pickup/index'));
const BoxPickupScreen = lazy(() => import('./pages/box_pickup/index'));
const Checklists = lazy(() => import('./pages/checklists'));

const App = () => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      setSession(session);
    });

    return () => { 
      if (authListener) {
        authListener?.subscription?.unsubscribe();
      }
    }
  }, []);

  useEffect(() => {
    const handleMagicLinkRedirect = async () => {
      const hash = window.location.hash.substring(1);
      const params = new URLSearchParams(hash);
      const access_token = params.get('access_token');
      const refresh_token = params.get('refresh_token');

      if (access_token && refresh_token) {
        const { error } = await supabase.auth.setSession({ access_token, refresh_token });

        if (error) {
          console.error('Error setting session:', error.message);
        } else {
          window.location.href = '/dashboard'; // Redirect to the dashboard or another authenticated page
        }
      }
    };

    handleMagicLinkRedirect();
  }, []);

  return (
      <Router>
        {session && session.user ? (
          <AccountProvider>
            <AudioProvider>
              <LocationProvider>
                <OrdersProvider>
                    <Suspense
                      fallback={
                        <div className="loader-container">
                          <div className="loader-container-inner">
                            <div className="text-center">
                              <Loader type="ball-pulse-rise" />
                            </div>
                            <h6 className="mt-5">Loading...</h6>
                          </div>
                        </div>
                      }
                    >
                      <Fragment>
                        <AppHeader />
                        <div className="app-main">
                          <div className="app-main__outer">
                            <div className="app-main__inner">
                                
                                <Route exact path="/" component={KDS} />
                                <Route exact path="/recipe-box" component={RecipeBox} />
                                <Route exact path="/waste-entry" component={Waste} />
                                <Route exact path="/prep-dashboard" component={Prep} />
                                <Route exact path="/checklists" component={Checklists} />

                                <Route 
                                  exact 
                                  path="/kds" 
                                  render={() => (
                                    <ExpoBoxProvider>
                                      <KDS />
                                    </ExpoBoxProvider>
                                  )}
                                />

                                <Route exact path="/pickup" component={PickupScreen} />
                                <Route exact path="/box-pickup" component={BoxPickupScreen} />
                            </div>
                            <AppFooter />
                          </div>
                        </div>
                        <ToastContainer/>
                      </Fragment>
                    </Suspense>
                </OrdersProvider>
              </LocationProvider>
            </AudioProvider>
          </AccountProvider>
        ) : (
          <Suspense
            fallback={
              <div className="loader-container">
                <div className="loader-container-inner">
                  <div className="text-center">
                    <Loader type="ball-pulse-rise" />
                  </div>
                  <h6 className="mt-5">Loading...</h6>
                </div>
              </div>
            }
          >
            <Switch>
              <Route path="/" exact component={Login} />
              <Route path="/login" exact component={Login} />
              <Route path="/forgot_password" exact component={ForgotPassword} />
            </Switch>
          </Suspense>
        )}
      </Router>
  );
};

export default App;
