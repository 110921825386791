import React, { createContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import supabase from '../lib/supabase';

const AccountContext = createContext();
const UpdateAccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [account, setAccount] = useState(null);
  const [user, setUser] = useState(null);  // Add state to hold the Supabase user object
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      const session = supabase.auth.session();

      // Refresh the session if it exists and is close to expiring
      if (session && new Date().getTime() > session.expires_at * 1000 - 90 * 60 * 1000) { // 90 minutes before expiry
        supabase.auth.refreshSession().catch(console.error);
      }
    }, 60 * 60 * 1000); // check every 60 minutes, so we are sure to check within the 90 minute window above

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let authListener = null;
    // Fetch the user session and account info from Supabase
    const fetchUser = async () => {
      supabase.auth.getSession().then(async ({ data: { session } }) => {
        //console.log("Session fetched");
        const user = session?.user;
        if (user) {
          const { data, error } = await supabase
            .from('userinfo')
            .select('*, oauth_credentials(merchant_id)')
            .eq('user_id', user.id)
            .eq('oauth_credentials.integration_name','square')
            .single();
			    //console.log(data,data?.oauth_square?.merchant_id);
          //console.log("In fetchUser - userinfo data:",data);
          if (!error) {
            data.square_connected = (data?.oauth_square?.merchant_id !== undefined && data?.oauth_square?.merchant_id !== null);
            setAccount(data);
          } else {
            console.error('Error fetching user info:', error);
          }
        }
      });

      // Listen for changes to the authentication state
      const { data } = supabase.auth.onAuthStateChange((event, session) => {
        //console.log('Auth state changed:', event);
        if (session) {
          //console.log('Current session:', session);
          const user = session?.user;
          user.access_token = session?.access_token;
          setUser(user);  // Set the user object from session
          //const accessToken = session.access_token;
          //console.log('Access Token:', accessToken);
          // Handle the new access token, e.g., store it in local storage or update app state
        }
      });
      authListener = data;
    };    

    // Listen for the visibilityState to update session when visible
    const handleVisibilityChange = async () => {
      if (document.visibilityState === 'visible') {
        await fetchUser();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Initial fetch
    fetchUser();

    // Cleanup function
    return () => {
      //console.log("Cleaning up...");
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      if (authListener) {
        authListener?.unsubscribe(); 
      }
    };
  }, []);

  const logout = async () => {
    //console.log("Logging out!");
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error('Error logging out:', error.message);
    } else {
      setAccount(null);
      history.push('/login');
    }
  };

  return (
    <AccountContext.Provider value={{ account, user, setAccount, logout }}>
      <UpdateAccountContext.Provider value={setAccount}>
        {children}
      </UpdateAccountContext.Provider>
    </AccountContext.Provider>
  );
};

export { AccountContext, UpdateAccountContext, AccountProvider };
